import Playr from 'plyr';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

function setup() {
  const players = Array.from(document.querySelectorAll('video'));
  players.forEach((player) => {
    const plyr = new Playr(player, {
      controls: ['play-large'],
      hideControls: true,
      iconUrl: '/assets/images/play.svg',
      storage: { enabled: false },
      fullscreen: { enabled: false }
    });
  });

  const grids = Array.from(document.querySelectorAll('.grid'));
  grids.forEach((grid) => {
    const ms = new Masonry(grid, {
      itemSelector: '.grid--item',
      gutter: '.grid--gutter',
      initLayout: false,
      transitionDuration: 0
    });

    imagesLoaded(grid, () => { ms.layout() });
    ms.on('layoutComplete', () => grid.classList.add('grid--setup'));
    ms.layout();
  });
}

if (document.readyState === 'complete') {
  setup();
} else {
  document.addEventListener('DOMContentLoaded', setup);
}

